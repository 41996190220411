import { createSvgIcon } from '@mui/material';

const SocialMedia = createSvgIcon(
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='32'
    height='33'
    viewBox='0 0 32 33'
    fill='none'
  >
    <path
      d='M12.5807 29.0027C11.8735 29.0027 11.1952 28.7235 10.6951 28.2265C10.195 27.7295 9.91406 27.0555 9.91406 26.3526V25.759C9.91406 25.6536 9.95621 25.5524 10.0312 25.4779C10.1062 25.4033 10.208 25.3615 10.3141 25.3615C10.4201 25.3615 10.5219 25.4033 10.5969 25.4779C10.6719 25.5524 10.7141 25.6536 10.7141 25.759V26.3526C10.7141 26.8446 10.9107 27.3164 11.2608 27.6643C11.6109 28.0122 12.0857 28.2077 12.5807 28.2077C13.0758 28.2077 13.5506 28.0122 13.9007 27.6643C14.2507 27.3164 14.4474 26.8446 14.4474 26.3526V24.4551C14.4474 24.3497 14.4895 24.2486 14.5646 24.174C14.6396 24.0995 14.7413 24.0576 14.8474 24.0576C14.9535 24.0576 15.0552 24.0995 15.1302 24.174C15.2053 24.2486 15.2474 24.3497 15.2474 24.4551V26.3526C15.2474 27.0555 14.9664 27.7295 14.4663 28.2265C13.9663 28.7235 13.288 29.0027 12.5807 29.0027Z'
      fill='currentColor'
    />
    <path
      d='M10.3141 21.4156C10.208 21.4156 10.1062 21.3737 10.0312 21.2992C9.95621 21.2246 9.91406 21.1235 9.91406 21.0181V9.04756C9.91406 8.34471 10.195 7.67065 10.6951 7.17366C11.1952 6.67667 11.8735 6.39746 12.5807 6.39746C13.288 6.39746 13.9663 6.67667 14.4663 7.17366C14.9664 7.67065 15.2474 8.34471 15.2474 9.04756V19.4784C15.2474 19.5838 15.2053 19.6849 15.1302 19.7595C15.0552 19.834 14.9535 19.8759 14.8474 19.8759C14.7413 19.8759 14.6396 19.834 14.5646 19.7595C14.4895 19.6849 14.4474 19.5838 14.4474 19.4784V9.04756C14.4474 8.55557 14.2507 8.08372 13.9007 7.73583C13.5506 7.38794 13.0758 7.19249 12.5807 7.19249C12.0857 7.19249 11.6109 7.38794 11.2608 7.73583C10.9107 8.08372 10.7141 8.55557 10.7141 9.04756V21.0181C10.7141 21.1235 10.6719 21.2246 10.5969 21.2992C10.5219 21.3737 10.4201 21.4156 10.3141 21.4156Z'
      fill='currentColor'
    />
    <path
      d='M21.1667 27.1053C20.4594 27.1053 19.7811 26.8261 19.281 26.3291C18.781 25.8321 18.5 25.158 18.5 24.4552V15.6065C18.5 15.5011 18.5421 15.4 18.6172 15.3254C18.6922 15.2509 18.7939 15.209 18.9 15.209C19.0061 15.209 19.1078 15.2509 19.1828 15.3254C19.2579 15.4 19.3 15.5011 19.3 15.6065V24.4552C19.3 24.9472 19.4967 25.419 19.8467 25.7669C20.1968 26.1148 20.6716 26.3103 21.1667 26.3103C21.6617 26.3103 22.1365 26.1148 22.4866 25.7669C22.8367 25.419 23.0333 24.9472 23.0333 24.4552V14.4192C23.0333 14.3138 23.0755 14.2127 23.1505 14.1382C23.2255 14.0636 23.3272 14.0217 23.4333 14.0217C23.5394 14.0217 23.6412 14.0636 23.7162 14.1382C23.7912 14.2127 23.8333 14.3138 23.8333 14.4192V24.4552C23.8333 25.158 23.5524 25.8321 23.0523 26.3291C22.5522 26.8261 21.8739 27.1053 21.1667 27.1053Z'
      fill='currentColor'
    />
    <path
      d='M18.9 11.1836C18.7939 11.1836 18.6922 11.1417 18.6172 11.0671C18.5421 10.9926 18.5 10.8915 18.5 10.786V7.1501C18.5 6.44725 18.781 5.77319 19.281 5.2762C19.7811 4.77921 20.4594 4.5 21.1667 4.5C21.8739 4.5 22.5522 4.77921 23.0523 5.2762C23.5524 5.77319 23.8333 6.44725 23.8333 7.1501V9.63855C23.8333 9.74398 23.7912 9.84509 23.7162 9.91964C23.6412 9.99419 23.5394 10.0361 23.4333 10.0361C23.3272 10.0361 23.2255 9.99419 23.1505 9.91964C23.0755 9.84509 23.0333 9.74398 23.0333 9.63855V7.1501C23.0333 6.65811 22.8367 6.18626 22.4866 5.83837C22.1365 5.49048 21.6617 5.29503 21.1667 5.29503C20.6716 5.29503 20.1968 5.49048 19.8467 5.83837C19.4967 6.18626 19.3 6.65811 19.3 7.1501V10.7887C19.2993 10.8937 19.2568 10.9941 19.1819 11.0681C19.107 11.142 19.0056 11.1836 18.9 11.1836Z'
      fill='currentColor'
    />
    <path
      d='M8.00461 18.8318C7.3583 18.8322 6.73381 18.5994 6.24727 18.1766C5.76073 17.7538 5.44525 17.1698 5.35951 16.5332C5.27377 15.8966 5.4236 15.2507 5.78114 14.7156C6.13868 14.1806 6.67959 13.7927 7.30328 13.6243L9.96995 12.9035C10.0701 12.8817 10.1748 12.899 10.2626 12.9517C10.3503 13.0045 10.4142 13.0887 10.4413 13.187C10.4683 13.2853 10.4563 13.3901 10.4078 13.4799C10.3593 13.5696 10.278 13.6375 10.1806 13.6694L7.51395 14.3902C7.0362 14.5195 6.62972 14.8321 6.38392 15.2593C6.13813 15.6865 6.07315 16.1932 6.20328 16.6679C6.33341 17.1427 6.648 17.5467 7.07783 17.7909C7.50767 18.0352 8.01754 18.0998 8.49528 17.9705L10.0953 17.5385C10.1468 17.5216 10.2013 17.5154 10.2553 17.5201C10.3094 17.5248 10.3619 17.5403 10.4097 17.5658C10.4575 17.5914 10.4996 17.6263 10.5334 17.6685C10.5672 17.7106 10.592 17.7592 10.6063 17.8112C10.6206 17.8633 10.6241 17.9176 10.6166 17.971C10.6091 18.0244 10.5908 18.0758 10.5627 18.1219C10.5346 18.168 10.4973 18.208 10.4532 18.2394C10.409 18.2707 10.359 18.2929 10.3059 18.3044L8.70595 18.7364C8.47765 18.7998 8.24168 18.8319 8.00461 18.8318Z'
      fill='currentColor'
    />
    <path
      d='M14.8851 17.0481C14.779 17.0618 14.6717 17.033 14.587 16.9682C14.5022 16.9033 14.4468 16.8077 14.4331 16.7022C14.4193 16.5968 14.4482 16.4903 14.5134 16.406C14.5787 16.3218 14.675 16.2668 14.7811 16.2531L25.2904 13.4095C25.5269 13.3455 25.7485 13.2357 25.9424 13.0866C26.1363 12.9375 26.2987 12.7519 26.4204 12.5404C26.5421 12.3289 26.6207 12.0956 26.6517 11.8539C26.6827 11.6122 26.6655 11.3668 26.6011 11.1317C26.5366 10.8966 26.4262 10.6765 26.2762 10.4838C26.1261 10.2911 25.9393 10.1297 25.7265 10.0087C25.5137 9.88777 25.2789 9.80967 25.0357 9.77886C24.7925 9.74806 24.5456 9.76517 24.3091 9.8292L14.9491 12.3786C14.8489 12.4004 14.7442 12.3831 14.6564 12.3303C14.5687 12.2776 14.5048 12.1934 14.4777 12.0951C14.4507 11.9968 14.4627 11.892 14.5112 11.8022C14.5597 11.7124 14.641 11.6446 14.7384 11.6127L24.1011 9.07922C24.6091 8.94342 25.1463 8.96002 25.6449 9.12694C26.1435 9.29385 26.5813 9.60362 26.9032 10.0173C27.225 10.4309 27.4165 10.9299 27.4537 11.4515C27.4908 11.973 27.3718 12.4938 27.1117 12.9484C26.9408 13.2503 26.7114 13.5155 26.4368 13.7288C26.1622 13.9422 25.8478 14.0993 25.5117 14.1913L14.9917 17.0348C14.9568 17.0433 14.921 17.0478 14.8851 17.0481Z'
      fill='currentColor'
    />
    <path
      d='M8.00461 26.6547C7.3583 26.6552 6.73381 26.4224 6.24727 25.9996C5.76073 25.5768 5.44525 24.9928 5.35951 24.3562C5.27377 23.7196 5.4236 23.0737 5.78114 22.5386C6.13868 22.0035 6.67959 21.6157 7.30328 21.4473L18.6819 18.3679C18.7338 18.3502 18.7887 18.3434 18.8432 18.3477C18.8978 18.352 18.9509 18.3674 18.9993 18.393C19.0477 18.4185 19.0902 18.4536 19.1243 18.4962C19.1585 18.5388 19.1834 18.5878 19.1977 18.6404C19.212 18.6929 19.2152 18.7478 19.2073 18.8016C19.1993 18.8555 19.1804 18.9071 19.1515 18.9534C19.1227 18.9997 19.0845 19.0395 19.0396 19.0705C18.9946 19.1016 18.9436 19.1231 18.8899 19.1338L7.51395 22.2132C7.27739 22.2772 7.05585 22.3869 6.86196 22.536C6.66807 22.6852 6.50563 22.8708 6.38392 23.0823C6.13813 23.5095 6.07315 24.0162 6.20328 24.4909C6.33341 24.9657 6.648 25.3697 7.07783 25.6139C7.50767 25.8582 8.01754 25.9228 8.49528 25.7935L18.5513 23.0718C18.6028 23.0549 18.6573 23.0487 18.7113 23.0534C18.7654 23.0581 18.8179 23.0736 18.8657 23.0991C18.9135 23.1247 18.9556 23.1596 18.9894 23.2018C19.0232 23.2439 19.048 23.2925 19.0623 23.3445C19.0766 23.3966 19.0801 23.4509 19.0726 23.5043C19.0651 23.5577 19.0468 23.6091 19.0187 23.6552C18.9906 23.7013 18.9533 23.7413 18.9092 23.7727C18.865 23.804 18.815 23.8262 18.7619 23.8377L8.70328 26.5593C8.47577 26.6222 8.24076 26.6543 8.00461 26.6547Z'
      fill='currentColor'
    />
    <path
      d='M23.4241 22.5602C23.318 22.5739 23.2108 22.5452 23.126 22.4803C23.0413 22.4155 22.9859 22.3198 22.9721 22.2144C22.9583 22.109 22.9872 22.0024 23.0525 21.9182C23.1178 21.8339 23.214 21.7789 23.3201 21.7652L25.2908 21.2352C25.768 21.1056 26.174 20.7931 26.4195 20.3662C26.665 19.9394 26.73 19.4332 26.6001 18.9587C26.4652 18.4884 26.1488 18.09 25.7198 17.8503C25.2908 17.6106 24.784 17.5489 24.3095 17.6787L23.6615 17.8563C23.6107 17.87 23.5577 17.8737 23.5055 17.8671C23.4533 17.8605 23.4029 17.8437 23.3572 17.8178C23.3115 17.7918 23.2714 17.7572 23.2392 17.7158C23.207 17.6744 23.1833 17.6272 23.1695 17.5767C23.1556 17.5262 23.1519 17.4736 23.1586 17.4217C23.1652 17.3698 23.1821 17.3197 23.2082 17.2743C23.2343 17.2289 23.2692 17.1891 23.3108 17.1571C23.3525 17.1251 23.4 17.1015 23.4508 17.0878L24.1015 16.9129C24.776 16.7511 25.4874 16.8569 26.0847 17.208C26.682 17.559 27.118 18.1276 27.3002 18.793C27.4825 19.4584 27.3965 20.1682 27.0607 20.7717C26.7248 21.3751 26.1655 21.8247 25.5015 22.0249L23.5308 22.5549C23.4957 22.562 23.4597 22.5638 23.4241 22.5602Z'
      fill='currentColor'
    />
    <path
      d='M23.4396 14.3796V24.45C23.4382 25.047 23.1989 25.6192 22.7741 26.0413C22.3494 26.4634 21.7736 26.7012 21.1729 26.7026C20.5722 26.7012 19.9965 26.4634 19.5717 26.0413C19.1469 25.6192 18.9077 25.047 18.9062 24.45V15.5589'
      fill='currentColor'
    />
    <path
      d='M18.9062 10.8257V7.15005C18.9084 6.55327 19.1478 5.98153 19.5725 5.55954C19.9971 5.13755 20.5724 4.89956 21.1729 4.89746C21.7734 4.89956 22.3487 5.13755 22.7734 5.55954C23.198 5.98153 23.4375 6.55327 23.4396 7.15005V9.59874'
      fill='currentColor'
    />
    <path
      d='M10.3125 25.7111V26.3524C10.3125 26.9498 10.5513 27.5228 10.9764 27.9452C11.4015 28.3676 11.978 28.605 12.5792 28.605C13.1803 28.605 13.7569 28.3676 14.1819 27.9452C14.607 27.5228 14.8458 26.9498 14.8458 26.3524V24.4973L10.3125 25.7111Z'
      fill='currentColor'
    />
    <path
      d='M14.8458 19.8149V9.04751C14.8458 8.45009 14.607 7.87713 14.1819 7.45469C13.7569 7.03225 13.1803 6.79492 12.5792 6.79492C11.978 6.79492 11.4015 7.03225 10.9764 7.45469C10.5513 7.87713 10.3125 8.45009 10.3125 9.04751V21.0419L14.8458 19.8149Z'
      fill='currentColor'
    />
  </svg>,
  'SocialMedia'
);

export default SocialMedia;
