enum Permissions {
  ANALYTICS_VIEW = 'analytics_view',
  BASKET_ANALYSIS = 'basket_analysis',
  BUSINESS_TO_BUSINESS_SITE = 'business-to-business-site',
  CAMPAIGN_MANAGE = 'campaign_manage',
  CAMPAIGN_TENANT_REPORT = 'campaign-tenant-report',
  CAN_DELETE_STORES = 'can-delete-stores',
  CAPTIVE_PORTAL = 'captive-portal',
  CHATBOT_INTERESTS = 'chatbot-interests',
  COMMERCE_CONNECT = 'commerce-connect',
  CONFIG_MANAGE = 'config_manage',
  CONTACT_EXPORT = 'contact_export',
  CREATE_FLIPBOOK_ = 'create_flipbook_',
  CUSTOMER_ANALYSIS = 'customer_analysis',
  CUSTOMER_CUBE_MANAGE = 'customer_cube_manage',
  DATA_MANAGE = 'data_manage',
  FLIPBOOK = 'Flipbook',
  FLIPBOOK_EDIT = 'flipbook_edit',
  FORM_MANAGE = 'form_manage',
  FRAUD_REPORT = 'fraud-report',
  GET_FLIPBOOK_LINK = 'get_flipbook_link',
  INVOICE_CREATOR = 'invoice-creator',
  INVOICE_RESEND = 'invoice-resend',
  KPI_REPORT = 'kpi-report',
  LABEL_PRINTING = 'label_printing',
  LABEL_PRINTING_STORE_LIST = 'label_printing_store_list',
  LEAD_CREATE = 'lead_create',
  LEAD_CSV_DOWNLOAD = 'lead_csv_download',
  LEAD_MANAGE = 'lead_manage',
  LEAD_REASSIGN = 'lead_reassign',
  LIST_REPORT = 'list-report',
  LMS_LANE_CLOSED = 'LMS_Lane_closed',
  LMS_LANE_DEMO_TO_SALE = 'LMS_Lane_demo-to-sale',
  LMS_LANE_LEAD = 'LMS_Lane_lead',
  LMS_LANE_MISSED_FOLLOW_UP = 'LMS_Lane_missed-follow-up',
  LMS_LANE_TODAY_FOLLOW_UP = 'LMS_Lane_today-follow-up',
  LOYALTY = 'loyalty',
  LOYALTY_LIABILITY_REPORT = 'loyalty-liability-report',
  MANAGE_ALL_STORES = 'manage-all-stores',
  MANAGE_ALL_USERS = 'manage-all-users',
  MANAGE_MARKETERS_DASHBOARD = 'manage-marketers-dashboard',
  MANAGE_SHOPPERSDELIGHT_PWA = 'manage-shoppersDelight-pwa',
  MANAGE_STORE_USERS = 'manage-store-users',
  MEMBER_BILL_REPORT = 'member-bill-report',
  MEMBER_LOYALTY_REPORT = 'member-loyalty-report',
  MIGRATE_CUSTOMER = 'migrate_customer',
  OFFER_PROMO_TENANT_REPORT = 'offer-promo-tenant-report',
  OFFER_TENANT_REPORT = 'offer-tenant-report',
  OFFERS_MANAGEMENT = 'Offers-management',
  CREATE_OFFER = 'create-offer',
  ORDER_CAPTURE = 'order-capture',
  ORDER_MANAGEMENT = 'order-management',
  OVERALL_LEAD_MANAGEMENT = 'overall-lead-management',
  PRODUCT_ANALYSIS = 'product_analysis',
  PRODUCT_FREQUENCY = 'product_frequency',
  REFER_USERS = 'refer_users',
  RESEND_CUSTOMER_OTP = 'resend-customer-otp',
  SHOW_STORE_LEADS = 'show_store_leads',
  SOCIAL_MEDIA_REWARDS = 'social_media_rewards',
  STORE_ANALYSIS = 'store_analysis',
  STORE_HEALTH_REPORT = 'store-health-report',
  STORE_KPI_REPORT = 'store-kpi-report',
  STORE_STATUS_REPORT = 'store-status-report',
  OVERVIEW = 'overview',
  SURVEY = 'survey_manage',
  TIER_DOWNGRADE_REPORT = 'tier-downgrade-report',
  TRANSACTION_REPORT = 'transaction-report',
  UNBLOCK_LOYALTY_POINTS = 'unblock_loyalty_points',
  USER_CREATE = 'user_create',
  VIEW_CUSTOMER_OTP = 'view-customer-otp',
  VIEW_FLIPBOOK = 'view_flipbook',
  PERSONIFY = 'personify',
  CUSTOMER_360 = 'customer_360',
  FESTIVAL_ANALYSIS = 'festival_analysis',
  COPY_WRITE = 'copy-write',
  INSIGHTS = 'insights',
  OVERALL_LEAD_REPORT = 'overall-lead-report',
  STORE_CATEGORY_REPORT = 'store-category-report',
  SOURCE_EFFICIENCY_REPORT = 'source-efficiency-report',
  STORE_EFFICIENCY_REPORT = 'store-efficiency-report',
  STORE_PERSON_EFFICIENCY_REPORT = 'store-person-efficiency-report',
  PRODUCT_HIERARCHY_EFFICIENCY_REPORT = 'product-hierarchy-efficiency-report',
  LEAD_DASHBOARD = 'lead-dashboard',
  LEAD_HISTORY_REPORT="lead-history-report",
  STORE_SALES_PREDICTION = 'store-sales-prediction',
  CUSTOMER_360_AI_FIELDS = 'customer-360-ai-fields',
  JOURNEY_BUILDER_MANAGE = 'journey-builder-manage',
  APPROVE_JOURNEY = 'approve-journey',
}

export default Permissions;
