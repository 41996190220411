import { LinkType } from "../../Campaign/interfaces/Campaign";

export enum TemplateTypes {
  SMS = "sms",
  EMAIL = "email",
  WHATSAPP = "whatsapp",
  WEB_PUSH = "web_push",
  RCS = "rcs",
}
export enum TemplateMode {
  PROMOTIONAL = "promotional",
  TRANSACTIONAL = "transactional",
}
// if changed change backend too
export enum WhatsappContentType {
  TEXT = "Text",
  MEDIA = "Media",
}
export enum WhatsappMediaType {
  IMAGE = "Image",
  VIDEO = "Video",
  DOCUMENT = "Document",
}
export interface Template {
  id: number;
  name: string;
  subject?: string;
  template_id: string;
  content_mode?: TemplateMode;
  channel: TemplateTypes;
  content: string;
  meta?: {
    additionalDetails?: LinkType[];
    whatsappContentType?: WhatsappContentType;
    whatsappMediaType?: WhatsappMediaType;
    thumbnailUrl?: string;
    html?: string;
    css?: string;
  };
}
