import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const BasketAnalysis = lazy(
  () => import('../components/BasketAnalysis')
);

const BasketAnalysisRoute: RouteObject = {
  path: 'basket-analysis',
  element: <BasketAnalysis />,
};

export default BasketAnalysisRoute;
