import { RouteObject } from "react-router-dom";
import { TemplateTypes } from "../interfaces";
import { TemplateContextProvider } from "../context/TemplateContext";
import { lazy } from "react";

const UpsertPreview = lazy(
  () => import("../components/TemplateUpsertPreview/TemplateUpsertPreview")
);
const GrapesJsForm = lazy(
  () => import("../components/GrapesJsForm/GrapesJsForm")
);
const TemplatesOutlet = lazy(() => import("../components"));
const Templates = lazy(
  () => import("../components/TemplateContainer/TemplateContainer")
);
const TemplateTableContainer = lazy(
  () => import("../components/TemplateTableContainer/TemplateTableContainer")
);

const TemplateRoutes: RouteObject = {
  path: "/campaign/templates",
  element: <TemplatesOutlet />,
  children: [
    {
      path: "",
      element: (
        <TemplateContextProvider>
          <Templates />
        </TemplateContextProvider>
      ),
      children: [
        {
          path: "sms",
          element: <TemplateTableContainer type={TemplateTypes.SMS} />,
        },
        {
          path: "sms/:id",
          element: <UpsertPreview type={TemplateTypes.SMS} isEdit={true} />,
        },
        {
          path: "rcs",
          element: <TemplateTableContainer type={TemplateTypes.RCS} />,
        },
        {
          path: "rcs/:id",
          element: <UpsertPreview type={TemplateTypes.RCS} isEdit={true} />,
        },
        {
          path: "email",
          element: <TemplateTableContainer type={TemplateTypes.EMAIL} />,
        },
        {
          path: "whatsapp",
          element: <TemplateTableContainer type={TemplateTypes.WHATSAPP} />,
        },
        {
          path: "whatsapp/:id",
          element: <UpsertPreview type={TemplateTypes.WHATSAPP} isEdit={true}/>,
        },
      ],
    },
    {
      path: "email/:id",
      element: <GrapesJsForm />,
    },
  ],
};

export default TemplateRoutes;
