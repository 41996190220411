import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useState,
} from "react";
import { Template } from "../interfaces";

interface TemplateContextI {
  templates: { rows: Template[]; count: number };
  isLoading: boolean;
  refresh: boolean;
  setRefresh: (refresh: boolean) => void;
  setTemplates: (template: { rows: Template[]; count: number }) => void;
  setIsLoading: (status: boolean) => void;
}
const TemplateContext = createContext<TemplateContextI>({
  templates: { rows: [], count: 0 },
  isLoading: false,
  refresh: false,
  setRefresh: () => {},

  setTemplates: () => {},
  setIsLoading: () => {},
});

export const TemplateContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [templates, setTemplates] = useState<{
    rows: Template[];
    count: number;
  }>({ rows: [], count: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  return (
    <TemplateContext.Provider
      value={{
        templates,
        isLoading,

        refresh,
        setRefresh,

        setTemplates,
        setIsLoading,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};

export const useTemplates = () => {
  return useContext(TemplateContext);
};
