import EnvironmentConfig from '../interfaces/EnvironmentConfig';

const developmentConfig: EnvironmentConfig = {
  surveyJsKey: 'empty',

  dashboardClassic: 'http://localhost:5335',
  ruleServerUrl: 'http://localhost:3004',
  apiServerUrl: 'http://localhost:3007',
  casaWebAppUrl: 'http://localhost:3020',
  commerceConnect: 'http://commerce-connect.casaqa.ajira.tech',
  pwaUrl: 'qa.casarewards.com',
  dashboard: 'https://dashboard.casaqa.ajira.tech',
  ruleUIUrl: 'http://localhost:3009',
  keycloakConfig: {
    url: `http://localhost:8080/auth`,
    realm: `casa`,
    clientId: `casa_dashboard`,
  },
  socialMedia: {
    facebook: {
      appId: '787341686188506',
    },
    googleAds: {},
  },
};

export default developmentConfig;
