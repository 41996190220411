import { lazy } from 'react';
import { RouteObject } from 'react-router-dom';

const SocialMediaCampaignListContainer = lazy(
  () => import('../components/SocialMediaCampaignListContainer')
);

const SocialMediaAdRoute = lazy(
  () => import('../components/SocialMediaAd/SocialMediaAd')
);

const socialMediaCampaignListRoute: RouteObject = {
  path: 'social-media-campaign',
  children: [
    {
      index: true,
      element: <SocialMediaCampaignListContainer />
    },
    {
      path: '/social-media-campaign/:id',
      element: <SocialMediaAdRoute />
    }
  ]
};

export default socialMediaCampaignListRoute;
