import EnvironmentConfig from '../interfaces/EnvironmentConfig';

const production: EnvironmentConfig = {
  surveyJsKey: "NzQ2YTcwNDMtMDcxMS00NjhkLWIyZjYtNDBmOTJjOTQwMzU3JmRvbWFpbnM6ZGFzaGJvYXJkLmNhc2FyZXRhaWwuaW4sZGFzaGJvYXJkLmNhc2EuYWppcmEudGVjaDsxPTIwMjUtMDMtMDcsMj0yMDI1LTAzLTA3LDQ9MjAyNS0wMy0wNw==",
  dashboardClassic: 'https://dashboard-classic.casaretail.in',
  ruleServerUrl: 'https://api.casa.ajira.tech',
  apiServerUrl: 'https://api.customerstudio.ajira.tech',
  casaWebAppUrl: 'https://web.casa.ajira.tech',
  commerceConnect: 'https://commerce-connect-up.casa.ajira.tech',
  ruleUIUrl: 'https://rules.casa.ajira.tech',
  pwaUrl: 'casarewards.com',
  dashboard: 'https://dashboard.casaretail.in',
  keycloakConfig: {
    url: `https://auth.casa.ajira.tech/auth`,
    realm: `casa`,
    clientId: `casa_dashboard`,
  },
  socialMedia: {
    facebook: {
      appId: '915596119986283',
    },
    googleAds: {},
  },
};

export default production;
